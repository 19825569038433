.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
}

.title {
  font-family: "Thunderbold Demo";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 44px;
  margin-bottom: 0;

  /* background-image: linear-gradient(90deg, #0247fe 0%, #4dd9ff 16%); */
  background-image: linear-gradient(90deg, #0247fe 0%, #4dd9ff 100%);
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.border_bottom {
  background: linear-gradient(
    97.65deg,
    #0247fe -0.45%,
    #4dd9ff 33.12%,
    #0247fe 64.48%,
    #4dd9ff 90.77%
  );
  height: 5px;
  border-radius: 5px;
  border: none;
  width: 200px;
}

.input_label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 5px;
}

.input {
  /* width: 100%; */
  width: 94%;
  height: 40px;
  border-radius: 15px;
  /* padding: 0 10px; */
  padding: 0 2%;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  background-color: transparent;

  border-image-source: linear-gradient(
    97.65deg,
    #0247fe -0.45%,
    #4dd9ff 33.12%,
    #0247fe 64.48%,
    #4dd9ff 90.77%
  );
  border-width: 2px;
  border-image-slice: 1;

  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.gradient_box {
  display: flex;
  align-items: center;
  width: 95%;
  margin: auto;
  position: relative;
  padding: 10px;
  box-sizing: border-box;
  color: #fff;
  /* background: #2e3435; */
  background: #000;
  background-clip: padding-box;
  border: solid 3px transparent;
  border-radius: 30px;
}

.gradient_box:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -3px;
  border-radius: inherit;
  background: linear-gradient(
    97.65deg,
    #0247fe -0.45%,
    #4dd9ff 33.12%,
    #0247fe 64.48%,
    #4dd9ff 90.77%
  );
}

.gradient_box img {
  width: 40%;
}

@media screen and (max-width: 1200px) {
  .gradient_box img {
    width: 50%;
  }
}

@media screen and (max-width: 1100px) {
  .gradient_box img {
    width: 60%;
  }
}

@media screen and (max-width: 768px) {
  .gradient_box img {
    width: 40%;
  }
}

@media screen and (max-width: 425px) {
  .gradient_box img {
    width: 60%;
  }
}

.submit_btn {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  /* padding: 0 10px; */
  padding: 0 2%;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  background: linear-gradient(90deg, #0247fe 0%, #4dd9ff 100%);
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
