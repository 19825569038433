.card {
  /* position: absolute;
  top: 50%;
  left: 50%; */
  /* width: 200px;
  height: 300px; */
  width: 250px;
  height: 330px;
  float: left;
  perspective: 500px;
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);

  transition: transform 1s;
  transform-style: preserve-3d;
}

.card:hover .content {
  transform: rotateY(180deg);
  transition: transform 0.5s;
}

.front,
.back {
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, #0247fe 0%, #4dd9ff 100%);

  /* line-height: 300px; */
  color: #03446a;
  text-align: center;
  /* font-size: 60px; */
  border-radius: 10px;
  backface-visibility: hidden;
}

.back {
  background: linear-gradient(90deg, #0247fe 0%, #4dd9ff 100%);
  color: white;
  transform: rotateY(180deg);
}

.name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  color: #ffffff;
  margin: 0;
}

.designation {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #ffffff;
  margin: 0;
  margin-top: 7px;
}

.details {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  margin: 0;
}

@media screen and (max-width: 1440px) {
  .card {
    width: 200px;
    height: 300px;
  }
  .name {
    font-size: 20px;
    line-height: 20px;
  }
  .designation {
    font-size: 15px;
    line-height: 15px;
  }
  .details {
    font-size: 15px;
    line-height: 15px;
  }
}

@media screen and (max-width: 1024px) {
  .card {
    width: 165px;
    height: 250px;
  }
  .name {
    font-size: 16px;
    line-height: 16px;
  }
  .designation {
    font-size: 13px;
    line-height: 13px;
  }
  .details {
    font-size: 13px;
    line-height: 13px;
  }
}

@media screen and (max-width: 768px) {
  .card {
    width: 200px;
    height: 300px;
  }
  .name {
    font-size: 20px;
    line-height: 20px;
  }
  .designation {
    font-size: 15px;
    line-height: 15px;
  }
  .details {
    font-size: 15px;
    line-height: 15px;
  }
}

@media screen and (max-width: 480px) {
  .card {
    width: 165px;
    height: 250px;
  }
  .name {
    font-size: 15px;
    line-height: 15px;
  }
  .designation {
    font-size: 13px;
    line-height: 13px;
  }
  .details {
    font-size: 13px;
    line-height: 13px;
  }
}
